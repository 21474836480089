.card {
    width: 100%;
    height: 100%;

    &__media,
    &__text {
        width: 100%;
        height: 50%;
    }

    &__media {

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__text {
        background-color: $white-color;
        color: $black-color;
        padding: 15px;

        @media only screen and(max-width: 720px) {
            padding: 30px;
        }

        &__title {
            font-size: 1.1em;
            text-transform: uppercase;
            font-weight: bold;
        }

        &__description {
            font-size: .7em !important;
            margin-top: 10px !important;
        }
    }

}