.contact {
    padding: 100px $side-padding-contact;
    background-color: $gray-color;

    @media only screen and(max-width: 720px) {
        padding: $side-padding;
    }
    
    &__title,
    &__subtitle,
    &__subtitle--mobile {
        color: $white-color;
        text-align: right;
        text-transform: uppercase;
    }

    &__title {
        font-size: 1.1em;
        padding-top: 15px;
        position: relative;

        &::before {
            content: '';
            background-color: $white-color;
            width: 60px;
            height: 2px;
            top: 0;
            right: 0;
            position: absolute;
        }
    }

    &__subtitle {
        font-size: 2.2em;
        font-weight: bold;
        margin-top: -10px;
        
        @media only screen and(max-width: 720px) {
            display: none;
        }
        
        &--mobile {
            display: none;
            font-weight: bold;
            margin-top: -10px;
            font-size: 2.2em;
            
            @media only screen and(max-width: 720px) {
                display: block;
            }
        }
    }

    &__form {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        @media only screen and(max-width: 720px) {
            flex-direction: column;
        }

        input,
        select,
        textarea {
            display: block;
            width: 100%;
            margin-bottom: 10px;
            padding: 15px;
            font-weight: bold;
            border: none;
            outline: none;
            border-radius: 0;
            font-size: 1.1em;

            &:last-child {
                margin-bottom: 0;

                @media only screen and(max-width: 720px) {
                    margin-bottom: 10px; 
                }
            }

            &::-webkit-input-placeholder,
            &::-moz-input-placeholder,
            &::-o-input-placeholder,
            &::-ms-input-placeholder {
                color: black;
                font-weight: bold;
                font-size: 1.1em;
            }
        }

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-image: url(../../icons/black-arrow.svg);
            background-repeat: no-repeat;
            background-position: 95% 50%;
            background-size: 15px;
        }

        &__left,
        &__right {
            width: 47%;

            @media only screen and(max-width: 720px) {
                width: 100%;
            }
        }

        &__right {
            position: relative;
            
            textarea {
                height: 60%;

                @media only screen and(max-width: 720px) {
                    height: 226px;
                    margin-bottom: 25px;
                }
            }

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $primary-color;
                color: white;
                border: none;
                text-transform: uppercase;
                width: 212px;
                height: 55px;
                border-radius: 27.5px;
                font-weight: bold;
                cursor: pointer;
                outline: none;
                width: 100%;
                position: absolute;
                bottom: 0;

                @media only screen and(max-width: 720px) {
                    position: relative;
                    margin-bottom: 20px;
                }

                .arrow-icon {
                    transform: rotate(-90deg);
                    right: 5%;
                    position: absolute;
                }
            }
        }
    }
}