.services-full {
    padding: 80px $side-padding;
    display: flex;
    flex-direction: column;

    &__title,
    &__subtitle {
        text-transform: uppercase;
    }

    &__title {
        position: relative;
        padding-top: 15px;
        font-size: 1.1em;

        &::before {
            content: '';
            position: absolute;
            background-color: $primary-color;
            width: 60px;
            height: 2px;
            top: 0;
            left: 0;
        }
    }

    &__subtitle {
        font-size: 2.2em;
        font-weight: bold;
        margin-top: -10px;
    }

    p {
        font-size: .95em;
        margin-top: 30px;
    }

    &__services-grid {
        width: 80%;
        align-self: center;
        
        &__card {
            height: 220px;
            width: 100%;
            box-shadow: 0 0 11px 0 rgba(0,0,0,0.2);
            margin-top: 30px;
            display: flex;

            @media only screen and(max-width: 720px) {
                flex-direction: column;
                height: auto;
                margin-top: 0;

                &:first-child {
                    margin-top: 30px;
                }
            }

            img {
                height: 100%;
                width: 320px;

                @media only screen and(max-width: 720px) {
                    width: 100%;
                    height: auto;
                }
            }

            &__info {
                padding: 30px;

                &--title {
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 1.2em;
                }

                &--description {
                    margin-top: 20px;
                }
            }
        }
    }
}