.who-we-are-full {
    padding: 50px $side-padding;
    display: flex;
    flex-direction: column;

    &__title,
    &__subtitle {
        text-transform: uppercase;
    }

    &__title {
        position: relative;
        padding-top: 15px;
        font-size: 1.1em;

        &::before {
            content: '';
            position: absolute;
            background-color: $primary-color;
            width: 60px;
            height: 2px;
            top: 0;
            left: 0;
        }
    }

    &__subtitle {
        font-size: 2.2em;
        font-weight: bold;
        margin-top: -10px;
    }

    p {
        font-size: .95em;
        margin-top: 30px;
    }
}