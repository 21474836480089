.clients-bar {
    padding: 60px $side-padding;
    display: flex;
    justify-content: space-between;

    @media only screen and(max-width: 720px) {
        flex-direction: column;
        align-items: center;
    
        img {
            width: 150px;
            margin: 35px;
        }
    }
    
}