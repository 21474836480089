.services {
    padding: 55px $side-padding;
    background: $primary-dark-color;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media only screen and(max-width: 720px) {
        flex-direction: column;
        align-items: center;
    }

    &__item {
        width: 22%;
        height: 400px;
        color: $white-color;

        @media only screen and(max-width: 720px) {
            width: 80%;
            height: 550px;
            margin-top: 30px;
            
            &:first-child {
                margin-top: 0;
                width: 100%;
                height: auto;
            }
        }

        .title,
        .subtitle {
            text-transform: uppercase;
        }

        .title {
            font-weight: bold;
            font-size: 2.2em;
            margin-top: -10px;
        }

        .subtitle {
            padding-top: 15px;
            font-size: 1.1em;
            position: relative;

            &::before {
                content: '';
                width: 100%;
                height: 2px;
                background-color: $white-color;
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        p {
            font-size: 0.95em;
            margin-top: 30px;
        }
    }
}