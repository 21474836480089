.flotillas {
    padding: 80px $side-padding;
    display: flex;
    flex-direction: column;

    &__flotillas-grid {

        
        &__item {
            margin-bottom: 40px;
            
            &__title {
                text-transform: uppercase;
                position: relative;
                padding-top: 5px;
                font-size: 2.2em;
                font-weight: bold;
                margin-top: -10px;

                &::before {
                    content: '';
                    position: absolute;
                    background-color: $primary-color;
                    width: 60px;
                    height: 3px;
                    top: 0;
                    left: 0;
                }
            }

            &__card {
                height: 220px;
                width: 100%;
                box-shadow: 0 0 11px 0 rgba(0,0,0,0.2);
                margin-top: 30px;
                display: flex;
                position: relative;

                @media only screen and(max-width: 720px) {
                    flex-direction: column;
                    height: auto;
                }
                
                &__icon {
                    align-self: center;
                    width: 400px;

                    @media only screen and(max-width: 720px) {
                        flex-direction: column;
                        margin: 60px 0 40px 0;
                    }
                }
    
                img {
                    height: 100%;
                    width: 320px;

                    @media only screen and(max-width: 720px) {
                        width: 100%;
                        height: auto;
                    }
                }
    
                &__info {
                    padding: 30px;
    
                    &--title {
                        font-weight: bold;
                        text-transform: uppercase;
                        font-size: 1.2em;
                    }
    
                    &--description {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}