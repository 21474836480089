.footer {
    padding: 43px $side-padding;
    background-color: $black-color;
    color: $white-color;

    @media only screen and(max-width: 720px) {
        padding: 43px 15%;
    }

    .logo-container {
        width: 20%;

        @media only screen and(max-width: 720px) {
            width: 100%;
        }

        &__logo {
            width: 100%;
        }
    }

    .info-bar {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;

        @media only screen and(max-width: 720px) {
            flex-direction: column;
        }

        & * {
            font-size: .93em;
        }

        &__second_item,
        &__third_item,
        &__fourth_item {
            
            @media only screen and(max-width: 720px) {
                margin-top: 20px;
            }

            li {
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 20px;
                }
            }
        }

        &__first_item {
            width: 20%;
            font-size: 13px;

            @media only screen and(max-width: 720px) {
                width: 100%;
            }
        }

        &__second_item {
            width: 40%;

            @media only screen and(max-width: 720px) {
                width: 100%;
            }

            li {
                margin-bottom: 20px;
            }
        }

        &__third_item {
            width: 15%;

            @media only screen and(max-width: 720px) {
                width: 100%;
            }
        }

        &__fourth_item {
            width: 15%;

            @media only screen and(max-width: 720px) {
                display: none;
            }
        }
    }

    .reserved-rights {
        margin-top: 30px;
        font-size: .93em;

        @media only screen and(max-width: 720px) {
            text-align: center;
        }

        strong {
            font-weight: bold;
        }
    }
}