.who-we-are {
    padding: 50px $side-padding;
    display: flex;

    @media only screen and(max-width: 720px) {
        padding: 20px 0 0 0;
    }

    @media only screen and(max-width: 720px) {
        flex-direction: column;
    }
    
    &__left,
    &__right {
        width: 50%;

        @media only screen and(max-width: 720px) {
            width: 100%;
        }
    }
    
    &__left {

        @media only screen and(max-width: 720px) {
            // padding: 0 $side-padding;
            padding: 0px 20 0 0;
        }

        &__title,
        &__subtitle {
            text-transform: uppercase;
        }
    
        &__title {
            position: relative;
            padding-top: 15px;
            font-size: 1.1em;
    
            &::before {
                content: '';
                position: absolute;
                background-color: $primary-color;
                width: 60px;
                height: 2px;
                top: 0;
                left: 0;
            }
        }
    
        &__subtitle {
            font-size: 2.2em;
            font-weight: bold;
            margin-top: -10px;
        }

        p {
            font-size: .95em;
            margin-top: 30px;
        }

        .btn-container {
            display: flex;
            align-items: center;
            margin-top: 40px;

            @media only screen and(max-width: 720px) {
                flex-direction: column;
            }

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $primary-color;
                color: white;
                border: none;
                text-transform: uppercase;
                width: 212px;
                height: 55px;
                border-radius: 27.5px;
                font-weight: bold;
                cursor: pointer;
                outline: none;
                margin-right: 60px;

                @media only screen and(max-width: 720px) {
                    margin-right: 0;
                    margin-bottom: 35px;
                }
    
                .back-icon {
                    margin-left: 20px;
                }
            }
        }
    }

    &__right {
        padding: 0 2.5% 0 10%;

        @media only screen and(max-width: 720px) {
            padding: 0;
            height: 274px;
            margin-top: 35px;
        }

        img {
            width: 100%;
            // height: 100%;
        }
    }
}