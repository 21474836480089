.our-allies {
    padding: 0 $side-padding 50px $side-padding;
    display: flex;
    flex-direction: column;

    &__title,
    &__subtitle {
        text-transform: uppercase;
    }

    &__title {
        position: relative;
        padding-top: 15px;
        font-size: 1.1em;

        &::before {
            content: '';
            position: absolute;
            background-color: $primary-color;
            width: 60px;
            height: 2px;
            top: 0;
            left: 0;
        }
    }

    &__subtitle {
        font-size: 2.2em;
        font-weight: bold;
        margin-top: -10px;
    }

    &__allies-banner {
        padding: 60px 100px;
        display: flex;
        justify-content: space-between;
        box-shadow: 0 0 11px 0 rgba(0,0,0,0.2);
        margin-top: 30px;

        @media only screen and(max-width: 720px) {
            flex-direction: column;
            align-items: center;
        
            img {
                width: 150px;
                margin: 35px;
            }
        }
    }
}