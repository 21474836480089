.contact-full {
    padding: 80px $side-padding;
    display: flex;
    flex-direction: column;

    &__title {
        text-transform: uppercase;
        position: relative;
        padding-top: 5px;
        font-size: 2.2em;
        font-weight: bold;
        margin-top: -10px;

        &::before {
            content: '';
            position: absolute;
            background-color: $primary-color;
            width: 60px;
            height: 2px;
            top: 0;
            left: 0;
        }
    }

    p {
        font-size: 1.2em;
        margin-top: 20px;
        
        span {
            margin-right: 30px;
        }
    }
}