*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
}

body {
    box-sizing: border-box;
}

ul {
    list-style: none;
}

.wp-logo {
    position: fixed;
    z-index: 999;
    width: 90px;
    bottom: 5%;
    right: 5%;
    cursor: pointer;

    @media only screen and(max-width: 720px) {
        width: 60px;
        right: 4%;
        bottom: 2%;
    }
}