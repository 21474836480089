header {
    // min-height: 100vh;
    position: relative;

    @media only screen and(max-width: 720px) {
        min-height: 470px;
    }

    .mobile-menu-mar {
        background-color: $primary-color;
        padding: 20px;
        width: 100%;
        height: 50px;
        max-height: 50px;
        display: flex;
        align-items: center;
        z-index: 999;
        // margin-top: -50px;
        border-bottom: 1px solid $white-color;

        @media only screen and(min-width: 720.1px) {
            display: none;
        }
    
        .mobile-logo {
            height: 15px;
            width: 100%;
            justify-self: center;
        }
    }
    
    .info-bar {
        background-color: $primary-color; 
        padding: 15px $side-padding;
        color: $white-color;
        display: flex;
        justify-content: center;

        @media only screen and(max-width: 720px) {
            padding: 5px $side-padding;
            flex-direction: column;
            align-items: center;
            // margin-top: 50px;
        }
        
        &__phone-information,
        &__email-information,
        &__time-information {
            display: flex;
            align-items: center;
            margin-left: 5px;
            text-align: center;

            @media only screen and(max-width: 720px) {
                margin-left: 10px;
            }

            &--icon {
                margin-left: 20px;
            }

            &--text {
                font-size: .75em;
                margin-left: 20px;

                @media only screen and(max-width: 720px) {
                    font-size: .68em;
                }
            }

            &--mobile-text {
                display: none;
                font-size: .68em;
                margin-left: 20px;
                line-height: 15px;
            }
        }

        &__phone-information {
            margin-left: 5px !important;
        }

        @media only screen and(max-width: 720px) {

            &__time-information {
                display: none;
            }

            &__email-information {
                &--text {
                    margin-left: 15px;
                }
            }

            &__phone-information {

                &--icon {
                    margin-left: 0;
                }

                &--text {
                    display: none;
                }
                
                &--mobile-text {
                    display: block;
                }
            }
        }

    }

    .main-header-container,
    .main-header-without-slider-container {

        .menu-container {
            height: 90px;
            border-bottom: 1px solid $white-color;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            margin: 0 $side-padding;
            position: relative;
 
            &__logo {

                @media only screen and(max-width: 1400px) { width: 350px; }
                @media only screen and(max-width: 1200px) { width: 300px; }
                @media only screen and(max-width: 1150px) { width: 250px; }
                @media only screen and(max-width: 880px) { width: 200px; }
                @media only screen and(max-width: 760px) { width: 150px; }
            }

            &__verified {
                position: absolute;
                right: 0;
                width: 80px;

                @media only screen and(max-width: 1200px) { width: 60px; }
            }

            &__menu {
                height: 100%;
                right: 140px;
                position: absolute;

                @media only screen and(max-width: 1280px) { right: 100px; }
                @media only screen and(max-width: 1000px) { right: 80px; }

                &__item {
                    height: 100%;
                    color: $white-color;
                    background-color: transparent;
                    border: none;
                    text-transform: uppercase;
                    padding: 0 15px;
                    font-size: 0.8em;
                    margin: 0 20px;
                    cursor: pointer;
                    position: relative;

                    @media only screen and(max-width: 1280px) { padding: 0 10px; }
                    @media only screen and(max-width: 1200px) { padding: 0 5px; }
                    @media only screen and(max-width: 1200px) { margin: 0 10px; }
                    @media only screen and(max-width: 820px) { margin: 0 5px; }

                    &:focus {
                        outline: none;
                    }

                    &::after {
                        content: '';
                        left: 50%;
                        width: 0;
                        height: 4px;
                        bottom: 0;
                        position: absolute;
                        background-color: $white-color;
                        transition: .3s;
                    }

                    &--selected,
                    &:hover {
                        font-weight: bold;

                        &::after {
                            content: '';
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            height: 4px;
                            position: absolute;
                            background-color: $white-color;
                        }
                    } 
                } 
            }
        }
    }

    .main-header-container {
        min-height: 110vh;
        position: relative;
        background-size: cover;
        -webkit-transition: background-image 0.2s ease-in-out;
        transition: background-image 0.2s ease-in-out;

        &--box {
            background-image: url(../../img/main.png);
        }

        &--trac {
            background-image: url(../../img/slider_tractocamion.png);
        }

        &--platforms {
            background-image: url(../../img/slider_plataforms.png);
        }

        &--dollies {
            background-image: url(../../img/slider_dolly.png);
        }
        
        @media only screen and(max-width: 1260px) {
            background-size: cover; 
        }

        @media only screen and(max-width: 720.1px) {
            display: none;
        }

        .box-slider {
            width: 50%;
            height: 350px;
            padding: 50px;
            background-color: rgba($black-color, .5);
            color: $white-color;
            margin: 60px $side-padding 0 $side-padding;

            hr {
                width: 60%;
            }

            &__title {
                font-weight: bold;
                margin-top: 20px;
                font-size: 3em;
            }

            &__subtitle {
                font-size: 2.5em;
            }

            &__title,
            &__subtitle {
                text-transform: uppercase;
                line-height: 60px;
            }

            &__description {
                margin-top: 30px;
            }
        }

        .slider-controls {
            position: absolute;
            display: flex;
            width: 100%;
            bottom: 0;

            &__item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25%;
                cursor: pointer;
                padding: 20px 0 60px 0;

                * { 
                    fill: rgba($white-color, .5);
                }

                ellipse,
                circle {
                    fill: none;
                    stroke: rgba($white-color, .5);                    
                }

                &--active,
                &:hover {
                    position: relative;

                    &::after {
                        content: '';
                        background-color: $white-color;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        height: 4px;
                    }

                    * { 
                        fill: $white-color;
                    }
    
                    ellipse,
                    circle {
                        fill: none;
                        stroke: $white-color;                    
                    }
                }
            }
        }
    }

    .main-header-without-slider-container {
        height: 60vh;
        background-size: cover;
        background-position: center;
        
        &--services {
            background-image: url('../../img/services@3x.png');
        }

        &--who-we-are {
            background-image: url('../../img/who-we-are.png');
        }

        &--flotilla {
            background-image: url('../../img/flotilla.png');
        }

        &--contact-us {
            background-image: url('../../img/contact.png');
        }

        .menu-container {

            @media only screen and(max-width: 720.1px) {
                display: none;
            }
        }
    }

    .mobile-slider-container {

        @media only screen and(min-width: 720.1px) {
            display: none;
        }

        &__slider {
            height: 360px !important;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            -webkit-transition: background-image 0.2s ease-in-out;
            transition: background-image 0.2s ease-in-out;

            
            &--box {
                height: 100%;
                background-image: url(../../img/main.png);
            }
            
            &--trac {
                background-image: url(../../img/slider_tractocamion.png);
            }
            
            &--platforms {
                background-image: url(../../img/slider_plataforms.png);
            }
            
            &--dollies {
                background-image: url(../../img/slider_dolly.png);
            }

            p.title {
                font-size: 2.5em;
                font-weight: bold;
                text-transform: uppercase;
                color: $white-color;
                padding-top: 40px;
                position: relative;
                margin-top: 60px;

                &::after {
                    content: '';
                    height: 1px;
                    width: 120%;
                    background-color: $white-color;
                    top: 0;
                    left: -10%;
                    position: absolute;
                }
            }

            svg {
                margin-top: 50px;
            }
        }

        &__slide-title {
            width: 100%;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $black-color;
            color: $white-color;
            font-size: 1.4em;
            font-weight: bold;
        }
    }

    .slider-titles {
        width: 100%;
        display: flex;
        background-color: $black-color;
        
        @media only screen and(max-width: 720px) {
            display: none;
        }

        &__item {
            color: $white-color;
            height: 70px;
            width: 25%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.2em;
            font-weight: bold;
        }
    }
}