.our-mision-vision {
    padding: 0 $side-padding 50px $side-padding;
    display: flex;
    flex-direction: column;

    &__title,
    &__subtitle {
        text-transform: uppercase;
    }

    &__title {
        position: relative;
        padding-top: 15px;
        font-size: 1.1em;

        &::before {
            content: '';
            position: absolute;
            background-color: $primary-color;
            width: 60px;
            height: 2px;
            top: 0;
            left: 0;
        }
    }

    &__subtitle {
        font-size: 2.2em;
        font-weight: bold;
        margin-top: -10px;
    }

    p {
        font-size: .95em;
        margin-top: 30px;
    }

    .cards-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px;
        
        &__card {
            margin-bottom: 40px;
            width: 48%;
            padding: 30px;
            box-shadow: 0 0 11px 0 rgba(0,0,0,0.2);

            @media only screen and(max-width: 720px) {
                width: 100%;
            }

            &__title {
                font-weight: bold;
                font-size: 1.2em;
            }

            &__description {
                color: #9a9a9a;

                strong {
                    font-weight: bold;
                    font-style: italic;
                }
            }
        }
    }
}