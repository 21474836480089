.counters {
    width: 100%;
    background-color: $primary-color;
    display: flex;
    justify-content: center;
    padding: 30px 0;

    @media only screen and(max-width: 720px) {
        flex-direction: column;
        align-items: center;
    }

    &__item {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and(max-width: 720px) {
            margin-bottom: 15px;
        }

        &__icon {
            @media only screen and(max-width: 720px) {
                width: 50%;
            }
        }

        &__info {
            color: $white-color;
            margin-left: 30px;

            @media only screen and(max-width: 720px) {
                width: 50%;
                margin-left: -5px;
            }

            p {
                font-size: 3.5em;
                line-height: 60px;
                width: 140px;
                font-weight: lighter;

                @media only screen and(max-width: 720px) {
                    font-size: 2.5em;
                }
            }

            span {
                font-weight: bold;
                font-size: 1.1em;
                text-transform: uppercase;

                @media only screen and(max-width: 720px) {
                    font-size: 1em;
                }
            }
        }
    }
}