// FONT
$default-font-size: 1.6rem;

// COLORS
$primary-color: #E70C0C;
$primary-dark-color: #B71313;
$secondary-color: #EE3746;
$white-color: #FFFFFF;
$black-color: #000;
$gray-color: #1E1E1E;

// PADDINGS
$side-padding: 3.5%;
$side-padding-contact: 15%;