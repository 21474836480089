.mobile-menu-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba($black-color, 0.35);
    z-index: 1000;
    position: fixed;

    .menu-list {
        width: 70vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        background-color: $primary-color;

        &__item {
            min-height: 42px;
            padding: 0 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $white-color;

            p {
                text-transform: uppercase;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: -0.12px;
                color: $white-color;
            }
        }

        &__item.submenu {
            padding: 10px 0 10px 30px;

            ul li {
                height: 35px;
                color: $primary-color;
            }
        }
    }
}